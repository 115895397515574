import React from "react";
import Button from "@atoms/ButtonAssessment";
import Text from "@atoms/TextAssessment";
import Heading from "@atoms/HeadingAssessment";
import Carousel from "./CarouselAssessment";

const EduPlan = ({
    planName,
    planPrice,
    planFeatures,
    appFeatures,
    onClickBuyNow,
}) => {
    return (

        <div className=" lg:mx-0 gap-6 pt-3 md:pt-14 pb-2 relative    ">
            <div className="bg-white max-w-[382px] rounded-xl mb-4 md:mb-0 h-[510px] md:h-[650px] relative">
                <div className=" pt-8 relative ">
                    <Text type="bodyCaption4" className="text-lg lg:text-2xl  font-bold leading-6 text-center ">
                        {planName}
                    </Text>
                    <Heading type={"subheading1"} className="text-center mt-2 ">
                        ₹ {planPrice}
                    </Heading>
                </div>
                <div className="list-none flex flex-col gap-3 md:gap-2 mt-5 mb-6 px-6 text-left">
                    {planFeatures.map((list, index) => (
                        <Text
                            key={index}
                            type="bodyCaption5"
                        >
                            {list.features}
                        </Text>
                    ))}
                    <div className="flex items-start gap-3 mt-2 ">
                        <ul className=" text-left list-disc  font-semibold">
                            {appFeatures.map((list, index) => (
                                <li
                                    key={index}
                                    className="mt-1 ml-7 font-normal text-xs lg:text-lg"
                                >
                                    {list.features}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center  absolute bottom-6 w-full px-4">
                    <Button
                        variant="primary"
                        className="bg-primary-100 w-[230px] md:w-full  "
                        rounded="rounded"
                        onClick={() => onClickBuyNow("BASIC")}
                    >
                        Buy Now
                    </Button>
                </div>
            </div>
        </div>

    );
};

export default EduPlan;

// EduPlan.propTypes = {
//     planName: PropTypes.string,
//     planPrice: PropTypes.number,
//     planFeatures: PropTypes.arrayOf(
//         PropTypes.shape({
//             features: PropTypes.string
//         })
//     ),
//     appFeatures: PropTypes.arrayOf(
//         PropTypes.shape({
//             features: PropTypes.string
//         })
//     ),
//     onClickBuyNow: PropTypes.func,
// };