import Heading from "@atoms/HeadingAssessment";
import Text from "@atoms/TextAssessment";
import PropTypes from "prop-types";
import Image from "next/image";
import Link from "next/link";

const EduCenterOfKnwoledge = ({
    imageSrc = "/images/education/knw1.svg",
    title = "Build Academic Resilience",
    desc = "Equip yourself with the tools and wisdom to conquer academic challenges",
    link = "",
}) => {
    return (
        <section className=" pt-4 md:pt-8 ">
            <div className={"text-center"}>
                <div className="bg-white border border-gray-300 rounded-xl max-w-[386px] lg:py-8 px-4 py-6">
                    <div className="flex flex-col items-center">
                        <Image
                            src={imageSrc}
                            width={150}
                            height={150}
                            className=""
                        />

                        <Text
                            type="bodyCaption4"
                            className="mt-6 mb-4 !text-2xl text-center"
                        >
                            {title}
                        </Text>

                        <Text
                            type="bodyStyleMedium"
                            className="mb-12 text-center"
                        >
                            {desc}
                        </Text>
                    </div>
                    <Link href={link}>
                        <div className="flex items-center gap-2 justify-center cursor-pointer">
                            <Text
                                type="bodyStyleExtraLarge"
                                className="!text-primary-100 !font-semibold"
                            >
                                Learn More
                            </Text>
                            <Image
                                src="/images/education/Arrow - Right14.svg"
                                width={24}
                                height={24}
                                className=""
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default EduCenterOfKnwoledge;

EduCenterOfKnwoledge.propTypes = {
    imageSrc: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
    link: PropTypes.string,
};