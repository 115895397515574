import React from "react";
import Container from "@atoms/ContainerAssessment";
// import Image from "next/image";
// import Heading from '@atoms/Heading';
import Text from "@atoms/TextAssessment";
import Image from "next/image";

const EduStudentReviewCard = ({
    desc,
}) => {
    return (
        <Container className="max-w-[1050px] card relative bg-white flex justify-center items-center  mx-6 py-6 px-6  md:py-8 mt-8 rounded-xl border border-neutral-300 w-[90%] lg:w-[452px]">
            <Text
                type="bodyStyleMedium"
                fontSize="text-sm md:text-lg "
                className="pt-3 text-neutral-60 font-normal leading-6 flex justify-center items-center line-clamp-6 min-h-[140px]"
            >
                {desc}
            </Text>
            <div className="absolute left-7 -top-6 overflow-visible">
                <div>
                    <div className="primQuote">
                        <Image
                            src="/images/education/PrimQuote.svg"
                            width={60}
                            height={60}
                            alt="quote"
                        />
                    </div>
                    <div className="secQuote">
                        <Image
                            src="/images/education/SecQuote.svg"
                            width={60}
                            height={60}
                            alt="quote"
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default EduStudentReviewCard;
